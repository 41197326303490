<template>
  <AppLoginPageLayout
    class="event-page"
    is-event>
    <template>
      <div class="d-flex justify-center">
        <img
          class="form-login__container--img"
          src="@/assets/icons/boston-lights.png">
      </div>
      <h1 class="form-login__container--title">
        Log In to Your Account
      </h1>
      <p
        v-if="merged"
        class="form-login__container--description">
        Your account has been set up, login again please.
      </p>
      <p
        v-if="loginInvitedUser"
        class="form-login__container--description">
        New password has been sent to your email.
      </p>
      <v-form
        ref="loginIn"
        @submit.prevent="signIn">
        <v-text-field
          v-model="initialCaseEmail"
          placeholder="Email"
          single-line
          filled
          dense
          data-test="sign_in_email_input"
          :error-messages="emailErrors"
          :append-icon="!!emailErrors.length? 'mdi-alert-circle-outline' : ''"
          prepend-inner-icon="mdi-email-outline"
          :error="!!emailErrors.length"
          autocomplete="email"
          name="login-email-address"
          type="email"
          @input="clearLoginErrorMessage('email')" />
        <v-text-field
          v-model="login.password"
          placeholder="Password"
          single-line
          :type="showPassword ? 'text' : 'password'"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          filled
          dense
          data-test="sign_in_password_input"
          :error-messages="passwordErrors"
          prepend-inner-icon="mdi-lock-outline"
          :error="!!passwordErrors.length"
          class=""
          name="login-password"
          autocomplete="current-password"
          @click:append="showPassword = !showPassword"
          @input="clearLoginErrorMessage('password')" />
        <ForgotPassword />
        <div class="mt-2 d-flex justify-center flex-column align-center">
          <v-btn
            :disabled="showSpinner"
            type="submit"
            data-test="log_in_button"
            color="lightBlue"
            class="bigger"
            x-large>
            Log In
          </v-btn>
          <v-btn
            v-if="$vuetify.breakpoint.smAndDown"
            class="mt-2 white--text bigger"
            color="black"
            x-large
            @click="$router.push({ name: 'event-sign-up' })">
            Sign up
          </v-btn>
        </div>
      </v-form>
    </template>
    <template #footer-image>
      <div class="d-flex justify-center">
        <img
          class="form-login__container--img-footer"
          src="@/assets/icons/SOURCERY_poweredby_web.png">
      </div>
    </template>
  </AppLoginPageLayout>
</template>

<script>
import {
  required, email, minLength,
} from 'vuelidate/lib/validators';
import AWS from '@/services/awsSdk';
import API from '@/services/graphql';
import AppLoginPageLayout from '@/components/App/AppLoginPageLayout';
import ConverEmailToLowerCase from '@/mixins/ConverEmailToLowerCase';
import ErrorsText from '@/constants/errors';
import {
  mapMutations, mapActions, mapState,
} from 'vuex';
export default {
  name: 'EventLogin',
  components: {
    AppLoginPageLayout,
    ForgotPassword: () => import('@/components/App/AppLoginPageLayout/ForgotPassword'),
  },
  mixins: [ConverEmailToLowerCase],
  props: {
    email: {
      type: String,
      default: null,
    },
    merged: {
      type: Boolean,
      default: false,
    },
    loginInvitedUser: {
      type: Boolean,
      default: false,
    },
    forceLoginFlag: {
      type: Boolean,
      default: false,
    },
    password: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    error: null,
    showPassword: false,
    pageObjName: 'login',
    login: {
      email: null,
      password: null,
      initialCaseEmail: null,
    },
  }),
  computed: {
    ...mapState({
      showSpinner: state => state.showSpinner,
    }),
    emailErrors() {
      const errors = [];
      if (!this.$v.login.email.$dirty) return errors;
      if (!this.$v.login.email.email) {
        errors.push('Invalid e-mail address');
      }
      if (!this.$v.login.email.required) {
        errors.push(ErrorsText.ENTER_REQUIRED_VALUE);
      }
      if (this.error && !this.error.includes('password')) {
        errors.push(this.error);
      }
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.login.password.$dirty) return errors;
      if (!this.$v.login.password.minLength) {
        errors.push('Passwords must be at least 8 characters long');
      }
      if (!this.$v.login.password.required) {
        errors.push(ErrorsText.ENTER_REQUIRED_VALUE);
      }
      if (this.error && this.error.includes('password')) {
        this.error == ErrorsText.ERROR_WITH_USERNAME_OR_PASSWORD ? errors.push(ErrorsText.ERROR_WITH_PASSWORD) : errors.push(this.error);
      }
      return errors;
    },
  },
  mounted() {
    const { email, password, forceLoginFlag } = this;
    if (forceLoginFlag) {
      this.forceLogin({
        email,
        password,
      });
    }
  },
  validations: {
    login: {
      email: {
        required, email,
      },
      password: {
        required, minLength: minLength(8),
      },
    },
  },
  async created() {
    // AWS.useEventConfig();
    this.clearListOfFilesForSubscriptions();
    this.clearListOfFilesToNotify();
    if (this.email) {
      this.initialCaseEmail = this.email;
    }
    const { uuid, email, password } = this.$route.query;
    if (uuid) {
      this.getTemporaryCredentials(uuid);
    } else if (email && password) {
      this.forceLogin({
        email, password,
      });
    }
  },
  methods: {
    ...mapMutations({
      spinner: 'spinner',
      clearListOfFilesForSubscriptions: 'ExportSchedule/clearListOfFilesForSubscriptions',
      clearListOfFilesToNotify: 'ExportSchedule/clearListOfFilesToNotify',
    }),
    ...mapActions({
      featureFlags: 'FeatureFlags/getListFeatureFlags',
    }),
    clearLoginErrorMessage() {
      this.error = null;
    },
    async getTemporaryCredentials(uuid) {
      try {
        const { data } = await API.getTemporaryCredentials({
          uuid,
        });
        if (data?.response) {
          this.forceLogin(data.response);
        }
      } catch (err) {
        console.log(err);
      }
    },
    forceLogin({ email, password } = {
    }) {
      if (email && password) {
        this.initialCaseEmail = email;
        this.login = {
          ...this.login,
          password,
        };
        this.signIn();
      }
    },
    signIn() {
      if (this.showSpinner) return;
      this.$v.login.$touch();
      if (!this.$v.login.$invalid) {
        this.spinner(true);
        const { email, password } = this.login;
        const dataToSend = {
          email,
          password,
        };
        AWS.login(dataToSend, this);
      }
    },
  },
};
</script>